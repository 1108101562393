import React, { useEffect, useRef, useState } from "react";
import classnames from "classnames";
import { LoadingIndicator, ErrorMessage } from "@jsq/ds/core";
import { addError } from "@jsq/observability";
import { useDashboardHub } from "../../contexts/DashboardHubContext";
import { Viewer } from "../../contexts/types";
import { DATA_SOURCES } from "./constants";
import * as styles from "./styles_vanilla.css";
import type { RevealViewProps, RVDashboard } from "./types";

export const RevealView: React.FC<RevealViewProps> = ({
  dashboard,
  height = 800,
  width = "100%",
  showFilters = false,
  canEdit = false,
  canSave = false,
  type,
  crosshairs = false,
  className,
  createNewDashboard = false,
  store,
  autoSelectFilterList = [],
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const viewerRef = useRef<Viewer>();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { isLoaded } = useDashboardHub();

  // Initialize the RevealView component
  const initializeDashboard = (dash: unknown) => {
    if (!window.$ || !window.$.ig) return;
    const viewer = new window.$.ig.RevealView("#revealView");
    viewerRef.current = viewer;
    // Disable export and save as features
    viewer.showExportImage = false;
    viewer.showExportToPDF = false;
    viewer.showExportToExcel = false;
    viewer.showExportToPowerPoint = false;
    viewer.canSaveAs = false;
    // Disable background color change
    viewer.canChangeVisualizationBackgroundColor = false;
    viewer.showRefresh = false;
    // If in create mode, create a new dashboard
    viewer.dashboard = createNewDashboard ? new window.$.ig.RVDashboard() : dash;
    viewer.singleVisualizationMode = type === "view";
    viewer.showFilters = showFilters;
    viewer.canEdit = canEdit;
    viewer.canSave = canSave;
    viewer.crosshairsEnabled = crosshairs;
    // Disable menu if canEdit is false
    viewer.showMenu = canEdit || false;

    const viewerDashboard = viewer.dashboard as RVDashboard;
    // Loop through the filters in autoSelectFilter
    for (const filter of viewerDashboard.filters) {
      const autoSelectFilter = autoSelectFilterList.find((f) => f.field === filter.title);
      if (autoSelectFilter) {
        // If given a title, auto select the label from that title or use the first value of the filters
        filter.getFilterValues((filterValues) => {
          const title = filterValues.find((v) => v.label === autoSelectFilter.title);
          filter.selectedValues = [title?.label ?? filterValues[0].label];
        });
      }
    }
    // Add data source
    viewer.onDataSourcesRequested = (callback) => {
      if (!window.$?.ig) return;
      const dataSource = new window.$.ig.RVRESTDataSource();
      dataSource.id = DATA_SOURCES[store].id;
      dataSource.title = DATA_SOURCES[store].title;
      dataSource.subtitle = DATA_SOURCES[store].subtitle;
      dataSource.useAnonymousAuthentication = true;
      callback(new window.$.ig.RevealDataSources([dataSource], [], false));
    };
    setIsLoading(false);
  };

  // Handle errors when loading the dashboard
  const handleError = (err: Error) => {
    setError(err.message);
    addError(err.message);
    setIsLoading(false);
  };

  // Load the dashboard once
  useEffect(() => {
    let mounted = true;

    const loadDashboard = () => {
      try {
        if (!isLoaded) return;
        if (!window.$ || !window.$.ig) return;
        if (createNewDashboard) {
          const newDashboard = new window.$.ig.RVDashboard();
          initializeDashboard(newDashboard);
        } else {
          window.$?.ig?.RVDashboard.loadDashboard(
            dashboard,
            (dash) => {
              if (!mounted) return;
              void initializeDashboard(dash);
            },
            (error) => {
              if (!mounted) return;
              handleError(new Error(`Failed to load dashboard: ${error.message}`));
            },
          );
        }
      } catch (err) {
        if (!mounted) return;
        handleError(err instanceof Error ? err : new Error("Failed to initialize Reveal"));
      }
    };

    loadDashboard();
    return () => {
      mounted = false;
    };
  }, [dashboard, isLoaded]);

  useEffect(() => {
    const viewer = viewerRef.current;
    if (!viewer) return;
    viewer.singleVisualizationMode = type === "view";
    viewer.showFilters = showFilters;
    viewer.canEdit = canEdit;
    viewer.canSave = canSave;
    viewer.crosshairsEnabled = crosshairs;
  }, [showFilters, canEdit, canSave, type, crosshairs]);

  return (
    <>
      {isLoading && <LoadingIndicator />}
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <div
        id="revealView"
        ref={containerRef}
        className={classnames(styles.revealView, className)}
        style={{
          display: isLoading || error ? "none" : "block",
          height,
          width,
        }}
      />
    </>
  );
};

export default RevealView;
